<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('accountsTransfers.form.creatAccountTransfer')"
          :description="$t('accountsTransfers.form.fromHereYouCanCreateNewTransferProcess')"
          v-if="$route.name == 'accountsTransfersCreate'"
        />
        <addHeader
          :title="$t('accountsTransfers.form.editAccountTransfer')"
          :description="
            $t('accountsTransfers.form.fromHereYouCanModifyConversionProcess')
          "
          v-if="$route.name == 'accountsTransfersEdit'"
        />

        <div class="mb-4 row">
          <formInput
            :title="$t('accountsTransfers.form.operationCode')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'accountsTransfersEdit'"
            maxlength="255" 
          />

          <connectionInput
            :title="$t('accountsTransfers.form.operationCode')"
            :group="$t('accountsTransfers.form.Groups')"
            v-model="item.invoice_group"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'accountsTransfersCreate'"
            :hasErorr="errors.invoice_group"
            :error="$t('accountsTransfers.form.NumberingIsRequired')"
          />

          <subFileInput :title="$t('accountsTransfers.form.operationFacility')"
                     v-model="item.file"
                     :file="item.file"
                     :path="'invoices'"
                     :hasErorr="errors.file"
                     :error="$t('allerts.ChooseASuitableFile')"/>

          <formInput
          :title="$t('salesPayments.form.reference')"
          v-model="item.refrance"
          maxlength="255" />

          <div class="form-group row col-md-6">
            <label class="col-md-4">{{$t('accountsTransfers.form.date')}}</label>
             <div class="col-md-7 icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('capitals.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('capitals.form.Now')"
                  v-model="item.date"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"/>
              </div>
          </div>

          <selectInput
            :title="$t('accountsTransfers.form.typeTransfer')"
            v-model="item.to_client_type"
            :values="[
              { name: $t('accountsTransfers.form.customer'), value: 0 },
              { name: $t('accountsTransfers.form.supplier'), value: 1 },
              { name: $t('accountsTransfers.form.partner'), value: 2 },
              { name: $t('accountsTransfers.form.account'), value: 3 },
            ]"
            :hasErorr="errors.to_client_type"
          />

          <selectInput
            :title="$t('accountsTransfers.form.typeOfTransfer')"
            v-model="item.from_client_type"
            :values="[
              { name: $t('accountsTransfers.form.customer'), value: 0 },
              { name: $t('accountsTransfers.form.supplier'), value: 1 },
              { name: $t('accountsTransfers.form.partner'), value: 2 },
              { name: $t('accountsTransfers.form.account'), value: 3 },
            ]"
            :hasErorr="errors.from_client_type"
          />


          <searchGroupInput
            v-if="item.to_client_type == 0"
            :type="'to_customer'"
            :values="$database.customers.filter((el) => el.stat == 1)"
            database="customers"
          />

          <searchGroupInput
            v-if="item.to_client_type == 1"
            :type="'to_supplier'"
            :values="$database.suppliers.filter((el) => el.stat == 1)"
            database="suppliers"
          />
          <searchGroupInput
            v-if="item.to_client_type == 2"
            :type="'to_partner'"
            :values="$database.partners.filter((el) => el.stat == 1)"
            database="partners"
          />
          <searchGroupInput
            v-if="item.to_client_type == 3"
            :type="'to_account'"
            :values="$database.accounts.filter((el) => el.stat == 1)"
            database="accounts"
          />

          <searchGroupInput
          v-if="item.from_client_type == 0"
          :type="'from_customer'"
          :values="$database.customers.filter((el) => el.stat == 1)"
          database="customers"
        />

        <searchGroupInput
          v-if="item.from_client_type == 1"
          :type="'from_supplier'"
          :values="$database.suppliers.filter((el) => el.stat == 1)"
          database="suppliers"

        />
        <searchGroupInput
          v-if="item.from_client_type == 2"
          :type="'from_partner'"
          :values="$database.partners.filter((el) => el.stat == 1)"
          database="partners"
        />
        <searchGroupInput
          v-if="item.from_client_type == 3"
          :type="'from_account'"
          :values="$database.accounts.filter((el) => el.stat == 1)"
          database="accounts"
        />

         
          <monyInput
            :title="$t('accountsTransfers.form.amount')"
            v-model="item.cost"
            :hasErorr="errors.cost"
            :error="$t('allerts.theAmountReceivedMustBeGreaterThan')"
          />


          <selectInput
            :title="$t('invoices.form.Situation')"
            v-model="item.status"
            :values="[
              { name: $t('invoices.draft'), value: 0 },
              { name: $t('invoices.approved'), value: 1 }
            ]"
          />

          <dvider
            :title="$t('accountsTransfers.form.notesTransfer')"
            :description="$t('accountsTransfers.form.notesTransferDetails')"
          />

          <formTextarea
            :title="$t('accountsTransfers.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea
            :title="$t('accountsTransfers.form.Note')"
            v-model="item.notes"
          />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'accountsTransfersCreate' && !$parent.stopEdit"
          >
            {{ $t('accountsTransfers.form.addOperationTransfer') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'accountsTransfersEdit' && !$parent.stopEdit"
          >
            {{ $t('accountsTransfers.form.editOperationTransfer') }}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";
import searchGroupInput from "../elements/add/form/searchGroupInput.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/accountsTransfers",
      item: {
        invoice_group: this.$option.accounts_transfers_invoice_group_id,
        date: "",
        status: 1,

        from_client_type: 0,
        to_client_type: 1,

        from_customer: {},
        from_partner: {},
        from_supplier: {},
        from_account: {},

        to_customer: {},
        to_partner: {},
        to_supplier: {},
        to_account: {},
      },
      model: false,
      errors: {
        invoice_group: false,
        cost: false
      },
      print: this.$route.name == "accountsTransfersCreate" ? true : false,
    };
  },
  mounted() {
    this.$updateDatabase([
      "invoiceGroups",
      "customers",
      "suppliers",
      "partners",
      "accounts"
    ]);

    if (this.$route.name == "accountsTransfersEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    } else {
      var params = this.$getParams()
    }
    if (this.$route.name == "accountsTransfersCreate") {
      var params = this.$getParams()
      
      this.item.date = this.$nowDate()
    }
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
      })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
          this.item.date = this.$dateFormat(this.item.date, true)
        });
    },
    saveItem() {
      var error = 0;
      if (
        typeof this.item.cost === "undefined" ||
        this.item.cost == "" ||
        this.item.cost == 0
      ) {
        error = 1;
        this.errors.cost = true;
      }
      if (
        (typeof this.item.invoice_group === "undefined" ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == "" ||
          this.item.invoice_group == null) &&
        this.$route.name == "accountsTransfersCreate"
      ) {
        error = 1;
        this.errors.invoice_group = true;
      }

      if (
        this.item.from_client_type == 0 && !this.item.from_customer_id 
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        this.item.from_client_type == 1 && !this.item.from_supplier_id 
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        this.item.from_client_type == 2 && !this.item.from_partner_id 
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        this.item.from_client_type == 3 && !this.item.from_account_id 
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        this.item.to_client_type == 0 && !this.item.to_customer_id 
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        this.item.to_client_type == 1 && !this.item.to_supplier_id 
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        this.item.to_client_type == 2 && !this.item.to_partner_id 
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        this.item.to_client_type == 3 && !this.item.to_account_id 
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == "accountsTransfersCreate") {
          this.$parent.aletText = this.$t('allerts.accountsTransfersSuccessfullyAdded');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          this.item.new_balance = parseFloat(this.item.old_balance) + parseFloat(this.item.cost);          

          //change safes balance
          
          localStorage.database= JSON.stringify(this.$database)
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "accountsTransfersEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText =
              this.$t('allerts.safeTransfersHasBeenModifiedSuccessfully');
              this.$parent.alertType = "success";
              this.$router.go(-1);
              this.$parent.stopEdit = false;
              return response;
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    loading,
    subFileInput,
    searchGroupInput,
    
    VueCtkDateTimePicker,
  },
};
</script>
